export const storage = {
  /**
   * Уникальный рандомный ключ, по которому backend идентифицирует пользователя и агрегирует запросы
   */
  uniqDeviceID: "mg_udi",
  /**
   * Ключи авторизации
   */
  jwt: "mg_at",
  /**
   * Ключ аутентификации пользователя (нужен для OTP и регистрации), хранится в SessionStorage
   */
  magnitIDCode: "mg_uic",
  /**
   * Параметры входа логина для обработки перезагрузок
   */
  savedOTPData: "mg_sod",
  /**
   * Параметры для установки модалки PWA на iOS, хранится в LocalStorage
   */
  promptInstall: "mg_pim",
  /**
   * Храним идентификатор сессии для kfp
   */
  ksid: "mg_ksid",
  /**
   * Храним флаг принятия политики cookie
   */
  cookie: "mg_uac",
  /**
   * Храним последний qrcode пользователя
   */
  qrcode: "mg_uqc",
  /**
   * Храним последнее состояние бонусных программ
   */
  bonuses: "mg_ubn",
  /**
   * Храним статус, что пользователь недозарегистрирован
   */
  incompleteProfile: "mg_icp",
  /**
   * Храним дату показа уведомления о любимых категориях
   */
  favoriteCategories: "mg_fcn",
  /**
   * Храним информацию о подписке устройства на пуши
   */
  pushSubscription: "mg_ups",
  /**
   * Храним дату обновления токена, полученного при подписке на пуши (мс)
   */
  pushSubscriptionUpdated: "mg_upu",
  /**
   * Сохранение последнего отправленного серверу состояния системного разрешения показа уведомлений
   */
  lastPushPermissionState: "mg_lps",
  /**
   * Храним UUID пользователя
   */
  uuid: "mg_uid",

  /**
   * Флаг что переход на подписки был из мобилки
   */
  subscriptionsFromApp: "mg_sapp",

  fallbackQrCode: "E0000000000000000T000000",
};

export enum CouponsStaticCategories {
  all = "Все",
  favorite = "Избранные",
}

export const helpContacts = {
  mail: {
    link: "mailto:info@magnit.ru",
    text: "info@magnit.ru",
  },
  tel: {
    link: "tel:88002009002",
    text: "8 800 200-90-02",
  },
} as const;
