import { type IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  // Отображение страницы Купоны (отправляется при попадании на страницу и при переключении табов)
  "CouponsPage:List:View": {
    ya: {
      eventName: "coupMain_view",
      page: "coupMain",
    },
  },
  // Нажатие на один из навигационных табов (категорий)
  "CouponsPage:TapBar:Click": {
    ya: {
      eventName: "coupMain_tapbar_tab_click",
      page: "coupMain",
      block: "tapbar",
      element: "tab",
    },
  },
  // Клик по кнопке «Назад»
  "CouponsPage:BackLink:Click": {
    ya: {
      eventName: "coupMain_back_click",
      page: "coupMain",
      element: "back",
    },
  },
  // Клик на контрол добавления в избранное (сердечко)
  "CouponsPage:CoupItemLike:Click": {
    ya: {
      eventName: "coupMain_like_click",
      page: "coupMain",
      element: "like",
    },
  },
  // Отображение карточки конкретного купона на странице купонов
  "CouponsPage:CoupItem:View": {
    ya: {
      eventName: "coupMain_item_view",
      page: "coupMain",
      element: "item",
    },
  },
  // Клик по конкретному купону на странице купонов
  "CouponsPage:CoupItem:Click": {
    ya: {
      eventName: "coupMain_item_click",
      page: "coupMain",
      element: "item",
    },
  },
  // Показ модалки с картой магнит
  "CouponsPage:CardModal:View": {
    ya: {
      eventName: "coupDetail_card_view",
      page: "coupDetail",
      block: "card",
    },
  },
  // Клик на "закрыть" модалки с картой магнит
  "CouponsPage:CardModalClose:Click": {
    ya: {
      eventName: "coupDetail_card_close_click",
      page: "coupDetail",
      block: "card",
      element: "close",
    },
  },
  // Отображение детальной страницы купона
  "CouponDetail:View": {
    ya: {
      eventName: "coupDetail_view",
      page: "coupDetail",
    },
  },
  // Нажатие на контрол добавления в избранное (сердечко)
  "CouponDetail:Like:Click": {
    ya: {
      eventName: "coupDetail_like_click",
      page: "coupDetail",
      element: "like",
    },
  },
  // Отображение алерта при добавлении купона в избранное
  "CouponDetail:LikeAlrt:View": {
    ya: {
      eventName: "coupDetail_likeAlrt_view",
      page: "coupDetail",
      element: "likeAlrt",
    },
  },
  // Тап по кнопке "Показать карту Магнит"
  "CouponDetail:ShowCardModal:Click": {
    ya: {
      eventName: "coupDetail_showCard_click",
      page: "coupDetail",
      element: "showCard",
    },
  },
  // Показ модалки Купон больше не действует
  "CouponDetail:CoupExpiredModal:View": {
    ya: {
      eventName: "coupDetail_coupNotValide_view",
      page: "coupDetail",
      block: "coupExpiring",
    },
  },
  // Клик на "смотреть товары" в модалке "Купон больше не действует"
  "CouponDetail:CoupExpiredModalButton:Click": {
    ya: {
      eventName: "coupDetail_coupNotValide_showCoups_click",
      page: "coupDetail",
      block: "coupExpiring",
      element: "showCoups",
    },
  },
  // Показ модалки "как собирать магнитики" (=клик по кнопке как собирать магнитики в случае если их не хватает
  "CouponDetail:MagnetsHelp:View": {
    ya: {
      eventName: "coupDetail_magnetsHelp_view",
      page: "coupDetail",
      block: "magnetsHelp",
    },
  },
  // Клик на кнопку "понятно" модалки "как собирать магнитики"
  "CouponDetail:MagnetsHelpButton:Click": {
    ya: {
      eventName: "coupDetail_magnetsHelp_ok_click",
      page: "coupDetail",
      block: "magnetsHelp",
      element: "ok",
    },
  },
  // Тап по кнопке «Написать»
  "CouponDetail:MailHelpBtn:Click": {
    ya: {
      eventName: "coupDetail_mail_click",
      page: "coupDetail",
      element: "mail",
    },
  },
  // Тап по кнопке «Позвонить»
  "CouponDetail:CallHelpBtn:Click": {
    ya: {
      eventName: "coupDetail_call_click",
      page: "coupDetail",
      element: "call",
    },
  },
};
