import { type IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "Navbar:View": {
    ya: {
      eventName: "tapBar_view",
      block: "tapBar",
    },
  },
  "Navbar:Main:Click": {
    ya: {
      eventName: "tapBar_today_click",
      block: "tapBar",
      element: "today",
    },
  },
  "Navbar:Card:Click": {
    ya: {
      eventName: "tapBar_card_click",
      chapter: null,
      page: null,
      block: "tapBar",
      element: "card",
    },
  },
  "Navbar:My:Click": {
    ya: {
      eventName: "tapBar_myMagnit_click",
      chapter: null,
      page: null,
      block: "tapBar",
      element: "myMagnit",
    },
  },
};
