import { type IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "MyBonusesPage:View": {
    ya: {
      eventName: "today_bonusMain_bonuses_view",
      chapter: "today",
      page: "bonusMain",
      block: "bonuses",
    },
  },
  "MyBonusesPage:Back:Click": {
    ya: {
      eventName: "today_bonusMain_back_click",
      chapter: "today",
      page: "bonusMain",
      element: "back",
    },
  },
};
