import { type IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "LoginPage:View": {
    ya: {
      eventName: "auth_onboardingFirst_view",
      chapter: "auth",
      page: "onboardingFirst",
    },
  },
  "LoginModal:Opener:Click": {
    ya: {
      eventName: "auth_onboardingFirst_continue_click",
      chapter: "auth",
      page: "onboardingFirst",
      element: "continue",
    },
  },
  "LoginModal:Phone:View": {
    ya: {
      eventName: "auth_phoneNumber_view",
      chapter: "auth",
      page: "phoneNumber",
    },
  },
  "LoginModal:Phone:Close": {
    ya: {
      eventName: "auth_phoneNumber_close_click",
      chapter: "auth",
      page: "phoneNumber",
      element: "close",
    },
  },
  "LoginModal:Phone:Click": {
    ya: {
      eventName: "auth_phoneNumber_enterPhone_click",
      chapter: "auth",
      page: "phoneNumber",
      element: "enterPhone",
    },
  },
  "LoginModal:Phone:Submit": {
    ya: {
      eventName: "auth_phoneNumber_continue_click",
      chapter: "auth",
      page: "phoneNumber",
      element: "continue",
    },
  },
  "LoginModal:Phone:Error": {
    ya: {
      eventName: "auth_phoneNumber_error_view",
      chapter: "auth",
      page: "phoneNumber",
    },
  },
  "LoginModal:OTP:View": {
    ya: {
      eventName: "auth_phoneConfirm_view",
      chapter: "auth",
      page: "phoneConfirm",
    },
  },
  "LoginModal:OTP:Back": {
    ya: {
      eventName: "auth_phoneConfirm_back_click",
      chapter: "auth",
      page: "phoneConfirm",
      element: "back",
    },
  },
  "LoginModal:OTP:Close": {
    ya: {
      eventName: "auth_phoneConfirm_close_click",
      chapter: "auth",
      page: "phoneConfirm",
      element: "close",
    },
  },
  "LoginModal:OTP:Code:Repeat": {
    ya: {
      eventName: "auth_phoneConfirm_resubmit_click",
      chapter: "auth",
      page: "phoneConfirm",
      element: "resubmit",
    },
  },
  "LoginModal:Success": {
    ya: {
      eventName: "auth_success_visible",
    },
  },
  "LoginModal:Error": {
    ya: {
      eventName: "auth_phoneConfirm_error_view",
      page: "phoneConfirm",
      chapter: "auth",
      block: "error",
    },
  },
  "LoginModal:Validation:Error": {
    ya: {
      eventName: "auth_validation_error_view",
      page: "validation",
      chapter: "auth",
      block: "error",
    },
  },
  "Onboarding:View": {
    ya: {
      eventName: "onboarding_slider_view",
      chapter: "onboarding",
      page: "slider",
    },
  },
  "Onboarding:Install:Click": {
    ya: {
      eventName: "onboarding_slider_addButton_click",
      chapter: "onboarding",
      page: "slider",
      element: "addButton",
      title: "addIcon",
    },
  },
  "Onboarding:Login:Click": {
    ya: {
      eventName: "onboarding_slider_auth_click",
      chapter: "onboarding",
      page: "slider",
      element: "auth",
    },
  },
  "Onboarding:Install:Finish:View": {
    ya: {
      eventName: "onboarding_slider_installFinish_view",
      chapter: "onboarding",
      page: "slider",
      block: "installFinish",
    },
  },
  "Onboarding:Install:Prompt:View": {
    ya: {
      eventName: "onboarding_slider_installPwa_view",
      chapter: "onboarding",
      page: "slider",
      block: "installPwa",
    },
  },
  "Onboarding:Install:Prompt:Ok": {
    ya: {
      eventName: "onboarding_slider_installPwa_ok_click",
      chapter: "onboarding",
      page: "slider",
      block: "installPwa",
      element: "ok",
    },
  },
  "Onboarding:Install:Prompt:Cancel": {
    ya: {
      eventName: "onboarding_slider_installPwa_cancel_click",
      chapter: "onboarding",
      page: "slider",
      block: "installPwa",
      element: "cancel",
    },
  },
};
