import { type IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "AddCardPage:View": {
    ya: {
      eventName: "addCard_view",
      page: "addCard",
      chapter: "",
    },
  },
  "AddCardPage:Card:Click": {
    ya: {
      eventName: "addCard_cardNumber_click",
      page: "addCard",
      element: "cardNumber",
      chapter: "",
    },
  },
  "AddCardPage:CVC:Click": {
    ya: {
      eventName: "addCard_cvc_click",
      page: "addCard",
      element: "cvc",
      chapter: "",
    },
  },
  "AddCardPage:Submit": {
    ya: {
      eventName: "addCard_continue_click",
      page: "addCard",
      element: "continue",
      chapter: "",
    },
  },
  "AddCardPage:Guide:Click": {
    ya: {
      eventName: "addCard_whereQR_click",
      page: "addCard",
      block: "typeCard",
      element: "whereQR",
      chapter: "",
    },
  },
  "AddCardPage:Guide:View": {
    ya: {
      eventName: "addCard_whereQR_view",
      page: "addCard",
      block: "whereQR",
      chapter: "",
    },
  },
  "AddCardPage:Card:Back:Click": {
    ya: {
      eventName: "addCard_back_click",
      page: "addCard",
      element: "back",
      chapter: "",
    },
  },
  "AddCardPage:Success": {
    ya: {
      eventName: "addCard_success",
      page: "addCard",
      block: "success",
      chapter: "",
    },
  },
  "AddCardPage:Error:View": {
    ya: {
      eventName: "addCard_error_view",
      page: "addCard",
      block: "error",
      chapter: "",
      error_type: "",
    },
  },
};
