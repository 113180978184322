export default defineNuxtPlugin(async (_nuxtApp) => {
  // @see https://mswjs.io/docs/recipes/merging-service-workers/
  if (localStorage.getItem("enableApiMocking")) {
    const { worker } = await import("~/plugins/msw/browser");
    worker.start({
      onUnhandledRequest: "bypass",
      serviceWorker: {
        url: "/firebase-messaging-sw.js",
      },
    });
  }
});
