import { type IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "ProfileIncompletePage:View": {
    ya: {
      eventName: "incomplete_personal_view",
      chapter: "incomplete",
      page: "personal",
    },
  },
  "ProfileIncompletePage:Name:Click": {
    ya: {
      eventName: "incomplete_personal_name_click",
      chapter: "incomplete",
      page: "personal",
      block: "name",
    },
  },
  "ProfileIncompletePage:Birthday:Click": {
    ya: {
      eventName: "incomplete_personal_birth_click",
      chapter: "incomplete",
      page: "personal",
      block: "birth",
    },
  },
  "ProfileIncompletePage:Email:Click": {
    ya: {
      eventName: "incomplete_personal_email_click",
      chapter: "incomplete",
      page: "personal",
      block: "email",
    },
  },
  "ProfileIncompletePage:Subscribe:SMS:Click": {
    ya: {
      eventName: "incomplete_personal_notice_sms_click",
      chapter: "incomplete",
      page: "personal",
      block: "notice",
      element: "sms",
    },
  },
  "ProfileIncompletePage:Submit": {
    ya: {
      eventName: "incomplete_personal_continue_click",
      chapter: "incomplete",
      page: "personal",
      block: "continue",
    },
  },
  "ProfileIncompletePage:Conditions:Click": {
    ya: {
      eventName: "incomplete_personal_infoPersonal_click",
      chapter: "incomplete",
      page: "personal",
      element: "infoPersonal",
    },
  },
  "ProfileIncompletePage:Rules:Click": {
    ya: {
      eventName: "incomplete_personal_infoRules_click",
      chapter: "incomplete",
      page: "personal",
      element: "infoRules",
    },
  },
  "ProfileIncompletePage:Success": {
    ya: {
      eventName: "incomplete_success",
    },
  },
};
