<script setup lang="ts">
import { useRouter } from "vue-router";
import VInlineButton from "@magnit/core/src/components/VInlineButton/VInlineButton.vue";
import VText from "@magnit/core/src/components/VText/VText.vue";
import type { IInlineButtonProps } from "@magnit/core/src/components/VInlineButton/VInlineButton.types";
import BackIcon from "~/assets/svg/icons/arrow.svg";

interface IPageBackProps {
  route?: string;
  buttonText?: string;
  pageTitle?: string;
  theme?: IInlineButtonProps["theme"];
  analyticEvent?: string;
}

const props = withDefaults(defineProps<IPageBackProps>(), {
  route: "",
  buttonText: "",
  pageTitle: "",
  theme: "secondary",
  analyticEvent: "Page:Back:Click",
});
const router = useRouter();
const { send } = useAnalytics();

const onClick = () => {
  send(props.analyticEvent);

  if (props.route) {
    router.push(props.route);
  } else {
    router.back();
    window.scrollTo(0, 0);
  }
};
</script>

<template>
  <div class="page-back">
    <VInlineButton
      class="page-back__button"
      :class="{ 'page-back__button_only-icon': !buttonText }"
      :icon-position="buttonText ? 'left' : undefined"
      :theme="theme"
      @click="onClick"
    >
      <template #icon>
        <BackIcon class="page-back__icon" />
      </template>
      <template v-if="buttonText">
        {{ buttonText }}
      </template>
    </VInlineButton>
    <VText v-if="pageTitle" tag="div" font="title-small" class="page-back__title">
      {{ pageTitle }}
    </VText>
    <div class="page-back__right">
      <slot name="right" />
    </div>
  </div>
</template>

<style lang="postcss">
.page-back {
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & &__button {
    margin-left: -6px;

    &_only-icon {
      margin-left: -10px;
    }
  }

  & &__icon {
    transform: rotate(180deg);
  }
}
</style>
