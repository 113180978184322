import { type IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "FC:View": {
    ya: {
      eventName: "favCategory_view",
      page: "favCategory",
      selectedCatList: "",
    },
  },
  "FC:About:View": {
    ya: {
      eventName: "favCategory_about_view",
      page: "favCategory",
      block: "about",
    },
  },
  "FC:About:Close:Click": {
    ya: {
      eventName: "favCategory_about_close_click",
      page: "favCategory",
      block: "about",
      element: "close",
    },
  },
  "FC:AboutCat:View": {
    ya: {
      eventName: "favCategory_aboutCat_view",
      page: "favCategory",
      block: "aboutCat",
      categoryName: "",
    },
  },
  "FC:AboutCat:Close:Click": {
    ya: {
      eventName: "favCategory_aboutCat_close_click",
      page: "favCategory",
      block: "aboutCat",
      element: "close",
      categoryName: "",
    },
  },
  "FC:Save:Click": {
    ya: {
      eventName: "favCategory_ok_click",
      page: "favCategory",
      element: "ok",
      selectedCatList: "",
    },
  },
  "FC:Save:Success:View": {
    ya: {
      eventName: "favCategory_success_view",
      page: "favCategory",
      block: "success",
      selectedCatList: "",
    },
  },
  "FC:Save:Error:View": {
    ya: {
      eventName: "favCategory_error_view",
      page: "favCategory",
      block: "error",
      error_type: "",
      catList: "",
    },
  },
  "FC:Back:Click": {
    ya: {
      eventName: "favCategory_back_click",
      chapter: "today",
      page: "favCategory",
      element: "back",
    },
  },
};
