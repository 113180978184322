import { type IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "PersonalPromotionsPage:View": {
    ya: {
      eventName: "offersMain_view",
      page: "offersMain",
    },
  },
  "PersonalPromotionsPage:Item:View": {
    ya: {
      eventName: "offersMain_item_view",
      page: "offersMain",
      element: "item",
    },
  },
  "PersonalPromotionsPage:Item:Click": {
    ya: {
      eventName: "offersMain_item_click",
      page: "offersMain",
      element: "item",
    },
  },
  "PersonalPromotionsPage:Back:Click": {
    ya: {
      eventName: "offersMain_back_click",
      page: "offersMain",
      element: "back",
    },
  },
  "PersonalPromotionsPage:Detail:View": {
    ya: {
      eventName: "offerDetail_view",
      page: "offerDetail",
    },
  },
  "PersonalPromotionsPage:Detail:Activate:Click": {
    ya: {
      eventName: "offerDetail_activate_click",
      page: "offerDetail",
      element: "activate",
    },
  },
  "PersonalPromotionsPage:Detail:Activate:Success": {
    ya: {
      eventName: "offerDetail_success_view",
      page: "offerDetail",
      block: "success",
    },
  },
  "PersonalPromotionsPage:Detail:Close": {
    ya: {
      eventName: "offerDetail_close_click",
      page: "offerDetail",
      element: "close",
    },
  },
};
