<script setup lang="ts">
import VEmpty from "@magnit/core/src/containers/VEmpty/VEmpty.vue";
import Error404Illustration from "@magnit/illustrations/src/assets/svg/illustrations/base/280-error-404.svg";
import VWrapperLocal from "~/components/VWrapperLocal.vue";
import VPageBack from "~/components/VPageBack.vue";

const { send } = useAnalytics();

onMounted(() => {
  const visitParams = {
    NotFoundURL: { [document.location.href]: { Реферер: document.referrer } },
  };
  send("Page:Error404:View", visitParams);
});

const onClickButton = () => {
  send("Error:ToMain:Click");
  clearError({ redirect: Routes.Main });
};
</script>

<template>
  <VWrapperLocal class="page-404">
    <VPageBack />

    <div class="page-404__centered">
      <VEmpty
        class="app-empty-404"
        :icon="Error404Illustration"
        :description="`Здесь ничего не нашлось, а вот<br>на главной странице много<br>полезной информации`"
        :secondary-button="{
          title: 'На главную',
        }"
        @click:secondary="onClickButton"
      />
    </div>
  </VWrapperLocal>
</template>

<style lang="postcss" scoped>
.page-404 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;

  &__centered {
    display: flex;
    flex-grow: 1;
  }
}
</style>
