import { type IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "MyStickersPage:View": {
    ya: {
      eventName: "today_magnets_view",
      chapter: "today",
      page: "magnets",
    },
  },
  "MyStickersPage:CollMagnets:View": {
    ya: {
      eventName: "today_magnets_collMagnets_view",
      chapter: "today",
      page: "magnets",
      block: "collMagnets",
    },
  },
  "MyStickersPage:Back:Click": {
    ya: {
      eventName: "today_magnets_back_click",
      chapter: "today",
      page: "magnets",
      element: "back",
    },
  },
};
