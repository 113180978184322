import { type IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "MySettings:Load": {
    ya: {
      eventName: "sett_setMain_load",
      chapter: "sett",
      page: "setMain",
    },
  },
  "MySettings:View": {
    ya: {
      eventName: "sett_setMain_view",
      chapter: "sett",
      page: "setMain",
    },
  },
  "MySettings:Scroll": {
    ya: {
      eventName: "sett_setMain_scroll",
      chapter: "sett",
      page: "setMain",
    },
  },
  "MySettings:Tab:Click": {
    ya: {
      eventName: "sett_setMain_myCard_click",
      chapter: "sett",
      page: "setMain",
      block: "myCard",
    },
  },
  "MySettings:Info:View": {
    ya: {
      eventName: "sett_setMain_info_view",
      chapter: "sett",
      page: "setMain",
      block: "info",
    },
  },
  "MySettings:Info:Call:Click": {
    ya: {
      eventName: "sett_setMain_info_call_click",
      chapter: "sett",
      page: "setMain",
      block: "info",
      element: "call",
    },
  },
  "MySettings:Info:Email:Click": {
    ya: {
      eventName: "sett_setMain_info_email_click",
      chapter: "sett",
      page: "setMain",
      block: "info",
      element: "email",
    },
  },
  "MySettings:Personal:View": {
    ya: {
      eventName: "sett_setMain_personal_view",
      chapter: "sett",
      page: "setMain",
      block: "personal",
    },
  },
  "MySettings:Personal:Name:Click": {
    ya: {
      eventName: "sett_setMain_personal_name_click",
      chapter: "sett",
      page: "setMain",
      block: "personal",
      element: "name",
    },
  },
  "MySettings:Personal:Email:Click": {
    ya: {
      eventName: "sett_setMain_personal_email_click",
      chapter: "sett",
      page: "setMain",
      block: "personal",
      element: "email",
    },
  },
  "MySettings:Personal:CheckOnline:View": {
    ya: {
      eventName: "sett_setMain_personal_checkOnline_view",
      chapter: "sett",
      page: "setMain",
      block: "personal",
      element: "checkOnline",
    },
  },
  "MySettings:Personal:CheckOnline:Click": {
    ya: {
      eventName: "sett_setMain_personal_checkOnline_click",
      chapter: "sett",
      page: "setMain",
      block: "personal",
      element: "checkOnline",
    },
  },
  "MySettings:Personal:Name:Save": {
    ya: {
      eventName: "sett_setMain_confirmName_ok",
      chapter: "sett",
      page: "setMain",
      block: "confirmName",
      element: "ok",
    },
  },
  "MySettings:Personal:Name:Back": {
    ya: {
      eventName: "sett_setMain_confirmName_back",
      chapter: "sett",
      page: "setMain",
      block: "confirmName",
      element: "back",
    },
  },
  "MySettings:ModalEmail:Alert:View": {
    ya: {
      eventName: "sett_checkEmail_alert_view",
      chapter: "sett",
      page: "checkEmail",
      block: "alert",
    },
  },
  "MySettings:ModalEmail:Alert:Later": {
    ya: {
      eventName: "sett_checkEmail_alert_later_click",
      chapter: "sett",
      page: "checkEmail",
      block: "alert",
      element: "later",
    },
  },
  "MySettings:ModalEmail:Alert:Ok": {
    ya: {
      eventName: "sett_checkEmail_alert_ok_click",
      chapter: "sett",
      page: "checkEmail",
      block: "alert",
      element: "ok",
    },
  },
  "MySettings:Personal:Email:View": {
    ya: {
      eventName: "sett_checkEmail_confirmEmail_view",
      chapter: "sett",
      page: "checkEmail",
      block: "confirmEmail",
    },
  },
  "MySettings:Personal:Email:Save": {
    ya: {
      eventName: "sett_checkEmail_confirmEmail_ok_click",
      chapter: "sett",
      page: "checkEmail",
      block: "confirmEmail",
      element: "ok",
    },
  },
  "MySettings:Personal:Email:Back": {
    ya: {
      eventName: "sett_checkEmail_confirmEmail_back_click",
      chapter: "sett",
      page: "checkEmail",
      block: "confirmEmail",
      element: "back",
    },
  },
  "MySettings:ModalEmail:Success:View": {
    ya: {
      eventName: "sett_checkEmail_success_view",
      chapter: "sett",
      page: "checkEmail",
      block: "success",
    },
  },
  "MySettings:ModalEmail:Success:Ok": {
    ya: {
      eventName: "sett_checkEmail_success_ok_click",
      chapter: "sett",
      page: "checkEmail",
      block: "success",
      element: "ok",
    },
  },
  "MySettings:Notice:View": {
    ya: {
      eventName: "sett_setMain_notice_view",
      chapter: "sett",
      page: "setMain",
      block: "notice",
    },
  },
  "MySettings:Notice:Sms:Click": {
    ya: {
      eventName: "sett_setMain_notice_sms_click",
      chapter: "sett",
      page: "setMain",
      block: "notice",
      element: "sms",
    },
  },
  "MySettings:Notice:Push:Click": {
    ya: {
      eventName: "sett_setMain_notice_push_click",
      chapter: "sett",
      page: "setMain",
      block: "notice",
      element: "push",
    },
  },
  "MySettings:Notice:Push:Instruction:Popup:View": {
    ya: {
      type: "sett_setMain_pushDesc_view",
      chapter: "sett",
      page: "setMain",
    },
  },
  "MySettings:Notice:Email:Click": {
    ya: {
      eventName: "sett_setMain_notice_email_click",
      chapter: "sett",
      page: "setMain",
      block: "notice",
      element: "email",
    },
  },
  "MySettings:Logout:View": {
    ya: {
      eventName: "sett_setMain_btnLogOut_view",
      chapter: "sett",
      page: "setMain",
      block: "btnLogOut",
    },
  },
  "MySettings:Logout:Out:Click": {
    ya: {
      eventName: "sett_setMain_btnLogOut_click",
      chapter: "sett",
      page: "setMain",
      block: "btnLogOut",
    },
  },
  "MySettings:Logout:ModalOut:View": {
    ya: {
      eventName: "sett_setMain_LogOut_view",
      chapter: "sett",
      page: "setMain",
      block: "LogOut",
    },
  },
  "MySettings:Logout:ModalOut:Submit": {
    ya: {
      eventName: "sett_setMain_LogOut_ok_click",
      chapter: "sett",
      page: "setMain",
      block: "LogOut",
      element: "ok",
    },
  },
  "MySettings:Logout:ModalOut:Back": {
    ya: {
      eventName: "sett_setMain_LogOut_back_click",
      chapter: "sett",
      page: "setMain",
      block: "LogOut",
      element: "back",
    },
  },
  "MySettings:DeleteAccount:Click": {
    ya: {
      eventName: "sett_setMain_deleteAcc_click",
      chapter: "sett",
      page: "setMain",
      element: "deleteAcc",
    },
  },
  "MySettings:DeleteAccount:Modal:View": {
    ya: {
      eventName: "sett_setMain_deleteAcc_view",
      chapter: "sett",
      page: "setMain",
      block: "deleteAcc",
    },
  },
  "MySettings:DeleteAccount:Modal:Confirm": {
    ya: {
      eventName: "sett_setMain_deleteAcc_confirm_click",
      chapter: "sett",
      page: "setMain",
      block: "deleteAcc",
      element: "confirm",
    },
  },
  "MySettings:DeleteAccount:Modal:Back": {
    ya: {
      eventName: "sett_setMain_deleteAcc_back_click",
      chapter: "sett",
      page: "setMain",
      block: "deleteAcc",
      element: "back",
    },
  },
  "MySettings:Cards:View": {
    ya: {
      eventName: "sett_cards_view",
      chapter: "sett",
      page: "cards",
    },
  },
  "MySettings:Cards:Add:Click": {
    ya: {
      eventName: "sett_cards_addCard_click",
      chapter: "sett",
      page: "cards",
      element: "addCard",
    },
  },
  "MySettings:Cards:Info:Click": {
    ya: {
      eventName: "sett_cards_info_click",
      chapter: "sett",
      page: "cards",
      element: "info",
    },
  },
  "MySettings:Cards:InfoPage:View": {
    ya: {
      eventName: "sett_cards_info_view",
      chapter: "sett",
      page: "cards",
      block: "info",
    },
  },
  "MySettings:Cards:InfoPage:Back": {
    ya: {
      eventName: "sett_cards_info_back_click",
      chapter: "sett",
      page: "cards",
      block: "info",
      element: "back",
    },
  },
  "MySettings:Ios:Instruction:View": {
    ya: {
      eventName: "sett_setMain_iconMagnit_view",
      chapter: "sett",
      page: "setMain",
      block: "iconMagnit",
    },
  },
  "MySettings:Ios:Instruction:Slide:View": {
    ya: {
      eventName: "sett_setMain_iconMagnit_slide_view",
      chapter: "sett",
      page: "setMain",
      block: "iconMagnit",
      element: "slide",
    },
  },
  "MySettings:Ios:Instruction:Copy:Click": {
    ya: {
      eventName: "sett_setMain_iconMagnit_copyLink_click",
      chapter: "sett",
      page: "setMain",
      block: "iconMagnit",
      element: "copyLink",
    },
  },
  "MySettings:Ios:Instruction:Version:View": {
    ya: {
      eventName: "sett_setMain_iosSettings_view",
      chapter: "sett",
      page: "setMain",
      block: "iosSettings",
    },
  },
  "MySettings:Ios:Instruction:Push:View": {
    ya: {
      eventName: "sett_setMain_pushNotify_view",
      chapter: "sett",
      page: "setMain",
      block: "pushNotify",
    },
  },
  "MySettings:Ios:Instruction:Push:Slide:View": {
    ya: {
      eventName: "sett_setMain_pushNotify_slide_view",
      chapter: "sett",
      page: "setMain",
      block: "pushNotify",
      element: "slide",
    },
  },
};
