export type IMobileOS = "other" | "android" | "ios";

interface ISearchData {
  identity: string;
  prop?: string;
  string?: string;
  subString?: string;
  versionSearch?: string;
}

const getMobileOS = (): IMobileOS => {
  if (typeof window === "undefined") {
    return "other";
  }

  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return "android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "ios";
  } else if (
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2 &&
    /MacIntel/.test(navigator.platform)
  ) {
    return "ios";
  }

  return "other";
};

export const getPlatform = (): "pwa" | "browser" => {
  return window.matchMedia("(display-mode: standalone)").matches
    ? "pwa"
    : "browser";
};

const getBrowserInfo = () => {
  const browsers: ISearchData[] = [
    {
      string: navigator.userAgent,
      subString: "Chrome",
      identity: "Chrome",
    },
    {
      string: navigator.userAgent,
      subString: "YaBrowser",
      versionSearch: "YaBrowser/",
      identity: "Yandex",
    },
    {
      string: navigator.userAgent,
      subString: "OmniWeb",
      versionSearch: "OmniWeb/",
      identity: "OmniWeb",
    },
    {
      string: navigator.vendor,
      subString: "Apple",
      identity: "Safari",
      versionSearch: "Version",
    },
    {
      prop: window.opera,
      identity: "Opera",
    },
    {
      string: navigator.vendor,
      subString: "iCab",
      identity: "iCab",
    },
    {
      string: navigator.vendor,
      subString: "KDE",
      identity: "Konqueror",
    },
    {
      string: navigator.userAgent,
      subString: "Firefox",
      identity: "Firefox",
    },
    {
      string: navigator.vendor,
      subString: "Camino",
      identity: "Camino",
    },
    {
      // for newer Netscapes (6+)
      string: navigator.userAgent,
      subString: "Netscape",
      identity: "Netscape",
    },
    {
      string: navigator.userAgent,
      subString: "MSIE",
      identity: "Explorer",
      versionSearch: "MSIE",
    },
    {
      string: navigator.userAgent,
      subString: "Gecko",
      identity: "Mozilla",
      versionSearch: "rv",
    },
    {
      // for older Netscapes (4-)
      string: navigator.userAgent,
      subString: "Mozilla",
      identity: "Netscape",
      versionSearch: "Mozilla",
    },
  ];

  const browserOS: ISearchData[] = [
    {
      string: navigator.platform,
      subString: "Win",
      identity: "Windows",
    },
    {
      string: navigator.platform,
      subString: "Mac",
      identity: "Mac",
    },
    {
      string: navigator.userAgent,
      subString: "iPhone",
      identity: "iPhone/iPod",
    },
    {
      string: navigator.platform,
      subString: "Linux",
      identity: "Linux",
    },
  ];

  let versionSearchString = "";

  const searchString = function (data: ISearchData[]) {
    for (let i = 0; i < data.length; i++) {
      const dataString = data[i].string;
      const dataProp = data[i].prop;
      const dataSubString = data[i].subString;

      versionSearchString = data[i].versionSearch || data[i].identity;

      if (dataString) {
        if (dataSubString && dataString.includes(dataSubString))
          return data[i].identity;
      } else if (dataProp) return data[i].identity;
    }
  };

  const searchVersion = (dataString: string) => {
    const index = dataString.indexOf(versionSearchString);
    if (index === -1) return;
    return parseFloat(
      dataString.substring(index + versionSearchString.length + 1),
    );
  };

  return {
    browser: searchString(browsers) || "An unknown browser",
    version:
      searchVersion(navigator.userAgent) ||
      searchVersion(navigator.appVersion) ||
      "an unknown version",
    os: searchString(browserOS) || "an unknown OS",
  };
};

export { getBrowserInfo, getMobileOS };
