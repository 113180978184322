import { type IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "App:Prompt:Banner:View": {
    ya: {
      eventName: "iconOnDesc_view",
      chapter: "",
    },
  },
  "App:Prompt:Banner:Click": {
    ya: {
      eventName: "iconOnDesc_click",
      block: "iconOnDesc",
      chapter: "",
    },
  },
  "App:Prompt:Banner:Cancel": {
    ya: {
      eventName: "iconOnDesc_back_click",
      block: "iconOnDesc",
      element: "back",
      chapter: "",
    },
  },
  "App:Install:Banner:View": {
    ya: {
      eventName: "successInstall_view",
      block: "successInstall",
      chapter: "",
    },
  },
  "App:Ios:Instruction:Popup:View": {
    ya: {
      eventName: "iconInstall_view",
      page: "iconInstall",
      chapter: "",
    },
  },
  "App:Ios:Instruction:Popup:Close:Click": {
    ya: {
      eventName: "iconInstall_back_click",
      page: "iconInstall",
      element: "back",
      chapter: "",
    },
  },
  "App:Ios:Instruction:Popup:Slide:View": {
    ya: {
      eventName: "iconInstall_slide_view",
      page: "iconInstall",
      element: "slide",
      chapter: "",
    },
  },
  "App:Ios:Instruction:Popup:Copy:Click": {
    ya: {
      eventName: "iconInstall_copyLink_click",
      element: "slide",
      page: "iconInstall",
      chapter: "",
    },
  },
  "App:Ios:Instruction:Popup:Sett:Link:Click": {
    ya: {
      eventName: "iconInstall_pushInstall_click",
      block: "iconInstall",
      element: "pushInstall",
      chapter: "",
    },
  },
  "App:Auth:Push:Popup:View": {
    ya: {
      eventName: "auth_pushPerm_view",
      chapter: "auth",
      block: "popUpPushPerm",
    },
  },
  "App:Auth:Push:Popup:Click:Accept": {
    ya: {
      eventName: "auth_pushPerm_ok_click",
      chapter: "auth",
      block: "popUpPushPerm",
      element: "ok",
    },
  },
  "App:Auth:Push:Popup:Click:Decline": {
    ya: {
      eventName: "auth_pushPerm_cancel_click",
      chapter: "auth",
      block: "popUpPushPerm",
      element: "cancel",
    },
  },
  "App:Auth:Push:Failure:View": {
    ya: {
      eventName: "auth_pushPerm_notTurnPush_view",
      chapter: "auth",
      block: "notTurnPush",
    },
  },
  "App:Profile:Push:Failure:View": {
    ya: {
      eventName: "sett_pushPerm_notTurnPush_view",
      page: "setMain",
      chapter: "sett",
      block: "notTurnPush",
    },
  },
  "App:Profile:Push:Popup:View": {
    ya: {
      eventName: "sett_setMain_popUpPushPerm_view",
      chapter: "sett",
      page: "setMain",
      block: "popUpPushPerm",
    },
  },
  "App:Profile:Push:Popup:Click:Accept": {
    ya: {
      eventName: "sett_setMain_popUpPushPerm_ok_click",
      chapter: "sett",
      page: "setMain",
      block: "popUpPushPerm",
    },
  },
  "App:Profile:Push:Popup:Click:Decline": {
    ya: {
      eventName: "sett_setMain_popUpPushPerm_cancel_click",
      chapter: "sett",
      page: "setMain",
      block: "popUpPushPerm",
    },
  },
  "App:Host:Auth:Push:Permission:Check:Granted": {
    ya: {
      eventName: "auth_pushPerm_pushPermToast_ok_click",
      page: "pushPerm",
      chapter: "auth",
      block: "pushPermToast",
      element: "ok",
    },
  },
  "App:Host:Auth:Push:Permission:Check:Declined": {
    ya: {
      eventName: "auth_pushPerm_pushPermToast_cancel_click",
      page: "pushPerm",
      chapter: "auth",
      block: "pushPermToast",
      element: "cancel",
    },
  },
  "App:Host:Profile:Push:Permission:Check:Granted": {
    ya: {
      eventName: "sett_setMain_pushPermToast_ok_click",
      page: "setMain",
      chapter: "sett",
      block: "pushPermToast",
      element: "ok",
    },
  },
  "App:Host:Profile:Push:Permission:Check:Declined": {
    ya: {
      eventName: "sett_pushPerm_pushPermToast_cancel_click",
      page: "setMain",
      chapter: "sett",
      block: "pushPermToast",
      element: "cancel",
    },
  },
  "Page:Error404:View": {
    ya: {
      eventName: "404error",
    },
  },
};
