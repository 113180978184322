import useAnalyticManager from "@magnit/analytic-events/src/hook";
import projectEvents from "~/config/events";

enum AnalyticPages {
  Landing = "auth",
  Main = "today",
  QrCode = "card",
  Profile = "sett",
  Coupons = "coupons",
}

export default () => {
  const { send: dispatch, events } = useAnalyticManager();

  const userStore = useUserStore();
  const abStore = useAbStore();
  const route = useRoute();

  const savedUserId = useLocalStorage<{
    uuid: string;
  }>(storage.uuid, {
    uuid: "",
  });

  events(projectEvents);

  const analyticPagesMatching: { [key: string]: AnalyticPages; } = {
    [RouteNames.Main]: AnalyticPages.Main,
    [RouteNames.Landing]: AnalyticPages.Landing,
    [RouteNames.Profile]: AnalyticPages.Profile,
    [RouteNames.Coupons]: AnalyticPages.Coupons,
    [RouteNames.QrCode]: AnalyticPages.QrCode,
  };

  const send = (name: string, params: Record<string, any> = {}) => {
    const props = <Record<string, any>>{
      ...params,
    };

    const event = projectEvents[name];

    props.magnit_id = savedUserId.value.uuid || userStore.profile.userId || null;

    props.platform = getPlatform();
    props.ab = abStore.experiments?.activeExperimentsGroupIds?.join(";") || null;

    if (event && event.ya && "chapter" in event.ya && !event.ya.chapter) {
      const pageName = analyticPagesMatching[route.name as RouteNames] || route.fullPath;

      props.chapter = pageName;
      if ("eventName" in event.ya && pageName !== route.fullPath) {
        props.eventName = `${pageName}_${event.ya.eventName}`;
      }
    }

    return dispatch(name, props);
  };
  return { send };
};
