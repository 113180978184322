import { type IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "Transactions:View": {
    ya: {
      eventName: "transactionsPWA_mainPage_view",
      page: "transactionsPWA",
      block: "mainPage",
    },
  },
  "Transactions:Filters:Type:Click": {
    ya: {
      eventName: "transactionsPWA_mainPage_operationType_click",
      page: "transactionsPWA",
      block: "mainPage",
      element: "operationType",
    },
  },
  "Transactions:Filters:ShopFormat:Click": {
    ya: {
      eventName: "transactionsPWA_mainPage_shopFormat_click",
      page: "transactionsPWA",
      block: "mainPage",
      element: "shopFormat",
    },
  },
  "Transactions:Filters:Month:Click": {
    ya: {
      eventName: "transactionsPWA_mainPage_selectMonth_click",
      page: "transactionsPWA",
      block: "mainPage",
      element: "selectMonth",
    },
  },
  "Transactions:Filters:Type:Clear:Click": {
    ya: {
      eventName: "transactionsPWA_mainPage_operationTypeClear_click",
      page: "transactionsPWA",
      block: "mainPage",
      element: "operationTypeClear",
    },
  },
  "Transactions:Filters:ShopFormat:Clear:Click": {
    ya: {
      eventName: "transactionsPWA_mainPage_shopFormatClear_click",
      page: "transactionsPWA",
      block: "mainPage",
      element: "shopFormatClear",
    },
  },
  "Transactions:Filters:Month:Clear:Click": {
    ya: {
      eventName: "transactionsPWA_mainPage_selectMonthClear_click",
      page: "transactionsPWA",
      block: "mainPage",
      element: "selectMonthClear",
    },
  },
  "Transactions:Error:View": {
    ya: {
      eventName: "transactionsPWA_mainPageError_view",
      page: "transactionsPWA",
      block: "mainPageError",
    },
  },
  "Transactions:Filters:Type:Modal:View": {
    ya: {
      eventName: "transactionsPWA_operationType_view",
      page: "transactionsPWA",
      block: "operationType",
    },
  },
  "Transactions:Filters:Type:Modal:Item:Click": {
    ya: {
      eventName: "transactionsPWA_operationType_option_click",
      page: "transactionsPWA",
      block: "operationType",
      element: "option",
    },
  },
  "Transactions:Filters:Type:Modal:Close:Click": {
    ya: {
      eventName: "transactionsPWA_operationType_close_click",
      page: "transactionsPWA",
      block: "operationType",
      element: "close",
    },
  },
  "Transactions:Filters:ShopFormat:Modal:View": {
    ya: {
      eventName: "transactionsPWA_shopFormat_view",
      page: "transactionsPWA",
      block: "shopFormat",
    },
  },
  "Transactions:Filters:ShopFormat:Modal:Item:Click": {
    ya: {
      eventName: "transactionsPWA_shopFormat_option_click",
      page: "transactionsPWA",
      block: "shopFormat",
      element: "option",
    },
  },
  "Transactions:Filters:ShopFormat:Modal:Close:Click": {
    ya: {
      eventName: "transactionsPWA_shopFormat_close_click",
      page: "transactionsPWA",
      block: "shopFormat",
      element: "close",
    },
  },
  "Transactions:Filters:Month:Modal:View": {
    ya: {
      eventName: "transactionsPWA_selectMonth_view",
      page: "transactionsPWA",
      block: "selectMonth",
    },
  },
  "Transactions:Filters:Month:Modal:Item:Click": {
    ya: {
      eventName: "transactionsPWA_selectMonth_option_click",
      page: "transactionsPWA",
      block: "selectMonth",
      element: "option",
    },
  },
  "Transactions:Filters:Month:Modal:Close:Click": {
    ya: {
      eventName: "transactionsPWA_selectMonth_close_click",
      page: "transactionsPWA",
      block: "selectMonth",
      element: "close",
    },
  },
  "Transactions:Operation:View": {
    ya: {
      eventName: "transactionsPWA_operationDetails_view",
      page: "transactionsPWA",
      block: "operationDetails",
    },
  },
  "Transactions:Operation:Error:View": {
    ya: {
      eventName: "transactionsPWA_operationDetailsError_view",
      page: "transactionsPWA",
      block: "operationDetailsError",
    },
  },
};
