<script setup lang="ts">
import VEmpty from "@magnit/core/src/containers/VEmpty/VEmpty.vue";
import ErrorSmileIllustration from "@magnit/illustrations/src/assets/svg/illustrations/base/112-error.svg";
import VStack from "@magnit/core/src/components/VStack/VStack.vue";

const { send } = useAnalytics();

const onClickReload = () => {
  send("Error:Refresh:Click");
  reloadPage();
};
</script>

<template>
  <VStack direction="column" height="100%" align="stretch" justify="stretch" gap="0">
    <div class="error-page-500">
      <div class="error-page-500__content">
        <VEmpty
          class="app-empty-500"
          :icon="ErrorSmileIllustration"
          title=" Сайт временно не работает"
          description="Мы уже чиним эту проблему — пожалуйста, попробуйте снова через 15 минут"
          :secondary-button="{
            title: 'Обновить',
          }"
          @click:secondary="onClickReload"
        />
      </div>
    </div>
  </VStack>
</template>

<style lang="postcss" scoped>
.error-page-500 {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;

  &__content {
    max-width: 385px;
  }
}
</style>
