import { type IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "MyAccrualsPage:View": {
    ya: {
      eventName: "benefits_view",
      page: "benefits",
      bonus_percent: "",
      amount: "",
      amount_max: "",
      positions: "",
      positions_max: "",
      aimed_percent: "",
    },
  },
  "MyAccrualsPage:AboutBenefits:Click": {
    ya: {
      eventName: "benefits_aboutBenefits_click",
      page: "benefits",
      block: "aboutBenefits",
    },
  },
  "MyAccrualsPage:FavCategory:View": {
    ya: {
      eventName: "benefits_favCategory_view",
      page: "benefits",
      block: "favCategory",
      selectedCatList: "",
    },
  },
  "MyAccrualsPage:FavCategory:Click": {
    ya: {
      eventName: "benefits_favCategory_click",
      page: "benefits",
      block: "favCategory",
    },
  },
  "MyAccrualsPage:FavCategory:Add:Click": {
    ya: {
      eventName: "benefits_favCategory_add_click",
      page: "benefits",
      block: "favCategory",
      element: "add",
      selectedCatList: "",
    },
  },
  "MyAccrualsPage:Faq:View": {
    ya: {
      eventName: "benefits_FAQ_view",
      page: "benefits",
      block: "FAQ",
    },
  },
  "MyAccrualsPage:Faq:Item:View": {
    ya: {
      eventName: "benefits_FAQ_item_view",
      page: "benefits",
      block: "FAQ",
      element: "item",
      text: "",
    },
  },
  "MyAccrualsPage:Back:Click": {
    ya: {
      eventName: "benefits_back_click",
      chapter: "today",
      page: "benefits",
      element: "back",
    },
  },
};
