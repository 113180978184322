import { type IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "MainPage:Dashboard:View": {
    ya: {
      eventName: "today_todayMain_widget_view",
      chapter: "today",
      page: "todayMain",
      block: "widget",
    },
  },
  "MainPage:Dashboard:MPlus:Click": {
    ya: {
      eventName: "today_todayMain_widget_mPlus_click",
      chapter: "today",
      page: "todayMain",
      block: "widget",
      element: "mPlus",
    },
  },
  "MainPage:Dashboard:FC:Click": {
    ya: {
      eventName: "today_todayMain_widget_favCategory_click",
      chapter: "today",
      page: "todayMain",
      block: "widget",
      element: "favCategory",
    },
  },
  "MainPage:Dashboard:QR:Click": {
    ya: {
      eventName: "today_todayMain_widget_qr_click",
      chapter: "today",
      page: "todayMain",
      block: "widget",
      element: "qr",
    },
  },
  "MainPage:Dashboard:Error:View": {
    ya: {
      eventName: "today_todayMain_widget_error_view",
      chapter: "today",
      page: "todayMain",
      block: "widget",
      element: "error",
    },
  },
  "MergeCardModal:View": {
    ya: {
      eventName: "auth_addPlastic_view",
      chapter: "auth",
      page: "addPlastic",
    },
  },
  "MergeCardModal:Submit": {
    ya: {
      eventName: "auth_addPlastic_continue_click",
      chapter: "auth",
      page: "addPlastic",
      element: "continue",
    },
  },
  "MergeCardModal:Skip": {
    ya: {
      eventName: "auth_addPlastic_skip_click",
      chapter: "auth",
      page: "addPlastic",
      element: "skip",
    },
  },
  "MainPage:Slot:View": {
    ya: {
      eventName: "todayMain_adFox_view",
      chapter: "today",
      page: "todayMain",
      block: "adFox",
    },
  },
  "MainPage:Slot:Click": {
    ya: {
      eventName: "todayMain_adFox_click",
      chapter: "today",
      page: "todayMain",
      block: "adFox",
    },
  },
  // Отображение слайдера с купонами на главной
  "MainPage:CoupCrsl:View": {
    ya: {
      eventName: "todayMain_coupCrsl_view",
      page: "todayMain",
      block: "coupCrsl",
    },
  },
  // Просмотр превью (карточки) купона на главной
  "MainPage:CoupItem:View": {
    ya: {
      eventName: "todayMain_coupCrsl_item_view",
      page: "todayMain",
      block: "coupCrsl",
      element: "item",
    },
  },
  // клик по превью (карточки) купона на главной
  "MainPage:CoupItem:Click": {
    ya: {
      eventName: "todayMain_coupCrsl_item_click",
      page: "todayMain",
      block: "coupCrsl",
      element: "item",
    },
  },
  // Клик на контрол добавления в избранное (сердечко)
  "MainPage:CoupItemLike:Click": {
    ya: {
      eventName: "todayMain_coupCrsl_like_click",
      page: "todayMain",
      block: "coupCrsl",
      element: "like",
    },
  },
  // Отображение алерта при добавлении купона в избранное
  "MainPage:CoupLikeAlrt:View": {
    ya: {
      eventName: "todayMain_likeAlrt_view",
      page: "todayMain",
      element: "likeAlrt",
    },
  },
  // Клик на стрелку перехода к странице списка купонов
  "MainPage:CoupCrslToAll:Click": {
    ya: {
      eventName: "todayMain_coupCrsl_all_click",
      page: "todayMain",
      block: "coupCrsl",
      element: "all",
    },
  },
  "MainPage:PersonalPromotions:View": {
    ya: {
      eventName: "todayMain_offers",
      page: "todayMain",
      block: "offers",
    },
  },
  "MainPage:PersonalPromotions:Item:View": {
    ya: {
      eventName: "todayMain_offers_item_view",
      page: "todayMain",
      block: "offers",
      element: "item",
    },
  },
  "MainPage:PersonalPromotions:Item:Click": {
    ya: {
      eventName: "todayMain_offers_item_click",
      page: "todayMain",
      block: "offers",
      element: "item",
    },
  },
  "MainPage:PersonalPromotions:AllTitle:Click": {
    ya: {
      eventName: "todayMain_offers_all_click",
      page: "todayMain",
      block: "offers",
      element: "all",
    },
  },
  "MainPage:PersonalPromotions:AllSlide:Click": {
    ya: {
      eventName: "todayMain_offers_allOffers_click",
      page: "todayMain",
      block: "offers",
      element: "allOffers",
    },
  },
  "MainPage:PushBanner:View": {
    ya: {
      eventName: "today_todayMain_pushInstall_view",
      page: "todayMain",
      element: "pushInstall",
      chapter: "today",
    },
  },
  "MainPage:PushBanner:Click": {
    ya: {
      eventName: "today_todayMain_pushInstall_click",
      page: "todayMain",
      element: "pushInstall",
      chapter: "today",
    },
  },
};
